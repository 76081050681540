/* MOBILE CSS */
@media screen and (max-width: 480px){

    .App-header {
        flex-direction: column;
        justify-content: center;
        padding: 20px 20px !important; 
    }

    .below {
        padding: 0 20px !important;
    }

    ul {
        padding-left: 0;
    }

    .contactMenu p {
        font-size: 7.5vw !important;
    }

    .close {
        font-size: 6.5vw !important;
    }

    .socialLogo img {
        width: 25px !important;
    }

    .experienceOuter {
        background-size: 230px;
      }

    .experienceTitle > span {
        display: block;
        float: none !important;
        padding: 3px 0;
    }

    .footer {
        padding: 0 10px 3px 10px !important;
      }

    .footer p {
        font-size: 14px;
    }

    h1 {
        font-size: 18.5vw !important;
    }

    p {
        font-size: 20px;
    }

    .portrait {
        height: 84vw !important;
        position: absolute !important;
        top: 27.5vw !important;
        left: 21.5vw !important;
    }

    .description {
        padding: 0;
    }

}

@media screen and (min-width: 1400px){

    .magicHeader {
        background: none;
    }
}