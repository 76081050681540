@font-face {
    font-family: 'Besley-Bold';
    src: url('./Typefaces/Besley-Bold.otf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Bagnard';
    src: url('./Typefaces/Bagnard.otf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat-Light';
    src: url('./Typefaces/Montserrat-Light.ttf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./Typefaces/Montserrat-Light.ttf');
    font-display: swap;
  }
  
* {
  font-display: swap;
}