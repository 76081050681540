:root {
  --background: #fff3ae;
  --primary-colour: rgb(32, 32, 32);
  --secondary-colour: #ac5145;
  --tertiary-colour: #585858;
  --nav-bg: #fffbe3;
}

.App {
  text-align: center;
  background: var(--background);
  min-height: 100vh;
  color: var(--primary-colour);
  transition: 0.4s ease transform;
}

.App-header {
  background-color: var(--background);
  margin: 0 auto;
  padding: 20px 60px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: var(--primary-colour);
  min-height: 38.2vh;
  text-align: left;
} 

.below {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  min-height: 61.8vh;
  margin: 0 auto;
  margin: -40px auto 40px auto;
}

h1, h2, h3, h4 {
  font-family: 'Bagnard', serif;
  font-display: swap;
}

p {
  font-family: 'Montserrat-Light', 'Quicksand', 'Helvetica', sans-serif;
  font-weight: 300;
  font-display: swap;
  font-size: 24px;
}

h1 {
  font-size: 12.5vh;
  position: relative;
  margin-top: 0;
}

h2 {
  padding-top: 0;
  margin-top: -1.25em;
  font-size: 1.3em;
}

.portrait {
  height: 57vh;
  position: absolute;
  top: 17.6vh;
  left: 13.5vh;
}

.magicHeader {
  transition: all 0.5s ease;
  opacity: 1;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: var(--nav-bg);
}

.contactMenu {
  position: fixed;
  background: var(--nav-bg);
  color: var(--primary-colour);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 100000;
  opacity: 0;
  transition: 0.4s all;
  pointer-events: none;
  /* transform: translateX(100%); */
}

.contactMenu p {
  font-size: 5vw;
  padding-left: 30px;
  position: relative;
}

.clickable::before {
  content: "";
  height: 2px;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  background: var(--secondary-colour);
  visibility: hidden;
  transform-origin: 0 100%;
  will-change: transform;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.magicHeader .clickable::before, .close::before {
  transform-origin: 100% 0 !important;
}

.socialLogoContainer .clickable::before {
  top: 0;
  bottom: unset;
  transform-origin: 100% 0%;
}

.clickable:hover::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.navButton {
  margin: 0;
  font-family: Bagnard;
  padding: 12px 14px;
  padding-bottom: 14px;
  font-size: 16px;
}

.close {
  position: absolute !important;
  color: black !important;
  top: 0;
  right: 0;
  font-size: 2.5vw !important;
  padding: 25px 30px
}

.clickable {
  cursor: pointer;
  position: relative;
}

.contactList {
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  font-weight: 600;
}

.contactList .copyable:hover {
  color: var(--primary-colour);
  cursor: pointer;
}

.socialLogoContainer {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.socialLogo {
  padding: 30px;
}

.socialLogo img {
  width: 38px;
}

.title img {
  opacity: 0;
  animation: fadeIn 1.6s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}


.titleTop, .titleBottom, .and {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: -3vh;
  /* Margin bottom is to fix inherent padding added from inline-block */
}

.titleTop, .titleBottom {
  padding: 2px;
}

.titleTop > span, .titleBottom > span, .and > span {
  display: inline-block;
  animation-fill-mode: forwards !important;
}

.titleTop > span {
  transform: translateY(-105%);
  animation: slideDown 1s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}

.and > span { 
  animation: slideLeft 1s;
  transform: translateX(105%);
}

.and:nth-of-type(2) > span {
  animation-delay: 1.2s;

}
.and:nth-of-type(3) > span {
  animation-delay: 1.3s;

}
.and:nth-of-type(4) > span {
  animation-delay: 1.4s;

}
.and:nth-of-type(5) > span {
  animation-delay: 1.5s;

}

.titleBottom > span {
  transform: translateY(105%);
  animation: slideUp 1s;
  animation-delay: 1.7s;
}

.description {
  max-width: 700px;
  padding: 60px;
  opacity: 0;
  animation: fadeIn 3.2s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.descriptionInner {
  margin-block-start: 0;
}

.myName {
  font-family: 'Helvetica', sans-seif;
  /* font-weight: 600; */
  color: var(--secondary-colour);
}

.experienceOuter {
  background-image: url(./Foto/coffee-stain.png);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 330px;
}

.sectionTitle {
  text-align: center;
  padding: 27px;
  padding-bottom: 15px;
  margin: 0 auto;
  font-size: 2em;
}

.experienceSection:nth-of-type(1) {
  margin-top: 20px !important;
}

.experienceSection {
  border-top: 1px solid var(--tertiary-colour);
  max-width: 700px;
}

.experienceTitle {
  margin-bottom: 0;
  color: var(--secondary-colour);
  font-size: 25px;
}

.experienceTitle > span {
  padding-right: 5px;
}

.experienceDescription {
  margin-top: 7px;
}

.faded {
  opacity: 0;
  transition: 2s all;
}

.fade-in {
  opacity: 1;
}

video {
  max-width: 100%;
  max-height: 340px;
  z-index: 0;
}

.videoCaption {
  font-size: 12px;
  margin-top: 4px;
  color: var(--tertiary-colour);
  font-weight: bold;
}

.videoContainer {
  text-align: center;
}

.innerVideoContainer {
  max-width: 100%;
  max-height: 340px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;
}

.innerVideoContainer:hover .linkContainer {
  opacity: 1;
}

.linkContainer {
  position: absolute;
  z-index: 1;
  min-width: 50%;
  font-weight: bold;
  background-color: var(--nav-bg);
  /* border: 2px solid var(--secondary-colour); */
  transition: opacity 0.6s;
  opacity: 0;

}

.experienceLink {
  display: inline-block;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-decoration: none;
  color: var(--primary-colour);
}

.tooltipPopup {
  position: absolute;
  z-index: 1000001;
  transform: translate(-50%, -70%);
  opacity: 1;
  animation: fadeSlide 1.4s ease-out;
  animation-fill-mode: forwards;
  animation-delay: .4s;
  pointer-events: none;
  font-size: 4.5vw;
  font-weight: bold;
  font-family: 'Bagnard', serif;
  background: var(--nav-bg);
  color: var(--tertiary-colour);
  border: 1px solid var(--tertiary-colour);
  padding: 4px 8px;
}

.footer {
  margin-top: 34px;
  padding: 26px 24px;
}

.footer p {
  font-size: 14px;
  margin: 0 14px;
}

@keyframes fadeSlide {
  0% { opacity: 1; transform: translate(-50%, -70%) }
  100% { opacity: 0; transform: translate(-50%, -90%) }
}

@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes slideUp {
  from {transform: translateY(105%)}
  to { transform: translateY(0%)}
}

@keyframes slideLeft {
  from {transform: translateX(105%)}
  to { transform: translateX(0%)}
}

@keyframes slideDown {
  from {transform: translateY(-105%)}
  to { transform: translateY(0%)}
}

span[role='img'] {
  padding: 0 7px;
}

.available {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 3px;
  border-radius: 50%;
}
